// @ts-nocheck
/* eslint-disable */

import { ConfigQuery as SourceConfigQuery } from "SourceQuery/Config.query"

import { Field } from "@tilework/opus"

export class ConfigQuery extends SourceConfigQuery {
  _getStoreConfigFields() {
    return [
      ...super._getStoreConfigFields(),
      new Field("locale"),
      new Field("elightwalk_citypricing_enable"),
      new Field("ordertracking_choose_color_active"),
      new Field("ordertracking_choose_color_visited"),
      new Field("ordertracking_redirect"),
      new Field("ordertracking_show_order_info"),
      new Field("ordertracking_show_order_items"),
      new Field("ordertracking_show_status_bar"),
      new Field("ordertracking_show_status_bar_view_page"),
      new Field("ordertracking_show_top_link"),
    ]
  }
}

export default new ConfigQuery()
